import React, { memo } from "react"
import Head from "../head"
import DropdownFixedNavbar from "../Navbars/DropdownFixedNavbar"
import FooterBlack from "../Footers/FooterBlack/index"
import * as classes from "./styles.module.scss"

interface LayoutProps {
  title: string
  description?: string
  image?: { id: string; url: string }
  children: React.ReactNode
}

const Layout = memo((props: LayoutProps) => {
  return (
    <>
      <Head {...props} />
      <DropdownFixedNavbar />
      <div className={classes.bodyContent}>{props.children}</div>
      <FooterBlack />
    </>
  )
})

export default Layout
